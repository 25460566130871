<template>
	<div data-component="transactions">
		<list-view :list="getList" />
		<table-view
			:table="getTable"
			:pagination="pagination"
			:totalItems="totalItems"
			:showSearch="false"
			@paginate="onPaginate"
		/>
	</div>
</template>

<script>

	import TableView    from '@/components/ui/views/tableView/TableView';
	import ListView     from '@/components/ui/views/listView/ListView';
	import routeParams  from '@/mixins/routeParams';
	import api          from '@/services/api';
	import dayjs        from 'dayjs';

	export default {
		components: {
			TableView,
			ListView
		},
		mixins: [routeParams],
		props: {
			entryRoute: {
				type: String,
				default: 'teaching'
			},
			teacherId: {
				type: Number,
				default: undefined
			}
		},
		data: () => ({
			transactions:  undefined,
			pagination: {
				perPage:     20,
				currentPage: 1,
				totalPages:  0
			},
			totals:        undefined,
			totalItems: 	 0
		}),
		computed: {
			isTeaching () {
				return this.entryRoute === 'teaching';
			},
			getBreadcrumbRoute () {
				return this.isTeaching ? '/teaching' : `/admin/users/${this.getUserId}`;
			},
			getTotalSales () {
				if (!this.totals) {
					return false;
				}
				return parseFloat(this.totals.total).toFixed(2);
			},
			getTotalPaidIncludingPending () {
				if (!this.totals?.payoutAmount) {
					return false;
				}
				return parseFloat(this.totals.payoutAmount).toFixed(2);
			},
			getTotalPaidToDate () {
				if (!this.totals?.estimatedTotalPayout) {
					return false;
				}
				return parseFloat(this.totals.estimatedTotalPayout).toFixed(2);
			},
			getTotalToBePaid () {
				if (!this.getTotalPaidToDate ||
						!this.getTotalPaidIncludingPending) {
					return false;
				}
				return parseFloat(this.getTotalPaidIncludingPending - this.getTotalPaidToDate).toFixed(2);
			},
			getList () {
				if (!this.transactions) {
					return false;
				}
				return [
					{
						text: `Total paid by students: £${this.getTotalSales}`
					},
					{
						text: `Total payouts to teacher to date (estimated): £${this.getTotalPaidToDate}`
					},
					{
						text: `Total payouts to teacher (including pending payouts): £${this.getTotalPaidIncludingPending}`
					},
					{
						text: `Total pending payouts to teacher (estimated): £${this.getTotalToBePaid}`
					}
				];
			},
			getTable () {
				if (!this.transactions) {
					return false;
				}
				return {
					headings: [
						'Enrolment date',
						'Course',
						'Student',
						'Payout date (est)',
						'Course cost (£)',
						'Teacher payout (£)'
					],
					rows: this.transactions.map((transaction) => {
						return {
							columns: [
								this.getDate(transaction.paymentDate),
								transaction.courseName,
								transaction.studentName,
								this.getDate(transaction.payoutDate),
								this.getPrice(transaction.amountPaid, 'Free'),
								this.getPrice(transaction.payoutAmount)
							]
						};
					})
				};
			},
			getBreadcrumbs () {
				if (this.getContext === 'admin') {
					return [
						{
							path: '/admin',
							text: 'Admin'
						},
						{
							path: '/admin/users',
							text: 'Users'
						},
						{
							path: `/admin/users/${this.getUserId}`,
							text: 'User'
						},
						{
							path: `/admin/users/${this.getUserId}/transactions`,
							text: 'Transactions'
						}
					];
				}
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/transactions',
						text: 'Transactions'
					}
				];
			}
		},
		mounted () {
			this.setTransactions();
		},
		methods: {
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setTransactions();
			},
			getPrice (item, mod) {
				if (item === null) {
					return mod || '0.00';
				}
				return item;
			},
			getDate (item) {
				return item ? dayjs(item).format('DD/MM/YYYY') : 'n/a';
			},
			async setTransactions () {
				const request = {
					include: 'metadata,totals',
					page: this.pagination.currentPage,
					per: this.pagination.perPage,
					sortBy: 'payment_date',
					sortDir: 'ASC'
				};
				if (!this.isTeaching) {
					request.teacherId = this.getUserId;
				}
				const response = await api[this.entryRoute].getTransactions(request);
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.transactions = response.results;
				this.totals = response.meta.totals[0];
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
